// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const cooperates = [
	{
		id: 1,
		tag: 'good',
		question: '合作优势',
		content: [
			'非常在意双方合作体验，不一锤子买卖(会有多个APP)；',
			'充分信任：不审稿，小额前付，真实产品体验不虚假；',
			'不丢面子：我的产品新颖有质感，好多功能是独创的；', 
			'省心：APP内每个单词/人物都能一键生成视频卡片。', 
		],
	},
	{
		id: 1,
		tag: 'history',
		question: '合作形式(包含但不限于)',
		content: [
			'按次合作：广告植入，介绍APP使用体验/用法/优势等；', 
			'周期合作：有背单词需求，每月顺手发4-6篇使用笔记；',
			'置顶留言：在热门英语、历史内容下置顶APP下载信息；', 
			'价格敏感。流量贵，少有新颖APP，感谢支持创新！'
		],
	}
];

export default cooperates;
