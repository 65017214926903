<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-3/5 text-left">
			<h1
				class="font-general-semibold text-4xl md:text-4xl xl:text-4xl text-left sm:text-left text-ternary-dark dark:text-primary-light mb-4"
			>
				UP主你好
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-left sm:text-left leading-none text-gray-400"
			>
				我是个独立开发者，力图开发些有质感的APP
			</p>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-left sm:text-left leading-none text-gray-400"
			>
				打算在小红书 B站 短视频做些低成本内容植入
			</p>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-left sm:text-left leading-none text-gray-400"
			>
				不长于运营，期待和你 朋友般的互利互惠
			</p>
			<!-- <div class="flex justify-center sm:block">
				<a
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						>Download CV</span
					></a
				>
			</div> -->
		</div>
		<!-- class="h-8/10 sm:w-1/2 sm:h-auto mx-auto" -->

		<!-- Banner right illustration -->
		<div class="w-full md:w-2/5 text-center">
			<img
				class="h-screen w-1/3 h-auto sm:w-4/5 sm:h-auto sm:h-auto mx-auto mt-5"
				src="@/assets/images/dragon.png"
				alt="Developer"
			/>
		
		</div>
	</section>
</template>

<style scoped></style>
